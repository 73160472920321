const LocationReviewsData = [
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Tiffany Garcia",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joshua M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Lee Stout",
        "service_type": "Garage Door Repair",
        "tech_name": "Christina M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Daniel Silva",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Rachel H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Abigail Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brandon S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Michael Cooper",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Victor T.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Debra Allen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brandon R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Robert Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Rachel W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Jennifer Murphy",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brian M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Steven Welch",
        "service_type": "Garage Door Opener",
        "tech_name": "Joseph T.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Benjamin Fitzgerald",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ryan B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Robert Alvarez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Laura F.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Jennifer Sherman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mark H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Maria Watson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Jacob Williams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joe M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Matthew Mendoza",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Chelsea S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Ronald Avila",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Rachael F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "James Thompson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Daniel M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Mark Griffin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Justin N.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Jennifer Hull",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Pamela S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Sara Glenn",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jason L.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Brad Gray",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kyle C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Debbie Mayo",
        "service_type": "Garage Door Repair",
        "tech_name": "Jason S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Heidi Adams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Scott B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Laura Diaz",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Danielle J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Jenna Newman",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Matthew A.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Ms. Martha Marshall",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Timothy Simmons",
        "service_type": "Garage Door Installation",
        "tech_name": "Amy M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Marvin Coleman",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Megan O.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Amy Montgomery",
        "service_type": "Garage Door Repair",
        "tech_name": "Crystal H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Jessica Price",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Gomez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Denise W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Mr. Benjamin Wilson PhD",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Matthew Larson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Erin M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Mary Nichols",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tracy C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Sandra Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jerry J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Caitlin Guerrero",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kimberly M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Cory Page",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Julie Z.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Amy Cobb",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Theresa C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Lisa Walker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sophia C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "William Brown",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lauren W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Ann Crawford",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Emily Hensley",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sandra R.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Andrew Wilson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Margaret B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "John Larson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jenna S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Matthew Cooper",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Steven R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Hicks",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mary D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Sarah Fleming",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Eric Bryant",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Holly H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Diane Turner",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Dustin S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Michael French",
        "service_type": "Garage Door Installation",
        "tech_name": "Amy W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Anne Pope",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jason M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Mr. Jose Arellano",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Olivia K.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Steven Joseph",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kim B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Catherine Salazar",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mary M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Peter Reynolds",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tara O.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Pamela Carr",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Vicki C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Nicole Wilson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sarah C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Francisco Fox",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Samuel H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "Michael Wood",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Katie T.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Susan Shaw",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Morgan G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Suzanne Rangel",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jacob W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Nicholas Rodriguez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Belinda S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Edwin Nunez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lisa S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Kristopher Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brittany J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Timothy Mcdowell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Charles R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Ronnie Brown",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Melissa J.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Leslie Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "James H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Alisha Rose",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Justin M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Marcus Brady",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Clinton H.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Daniel Gilmore",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Stephanie W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Laura Myers",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Eugene L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Laura Obrien",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Patrick C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Carrie Lawson",
        "service_type": "Garage Door Services",
        "tech_name": "Timothy C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Donna Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Michael G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Andrew Price",
        "service_type": "Residential Garage Door Services",
        "tech_name": "John Z.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Oscar Bradford",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Megan R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Debra Ortega",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Maria G.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Sarah James",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert L.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "John Brewer",
        "service_type": "Garage Door Installation",
        "tech_name": "Shane D.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Richard Munoz",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jody G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Jordan Gutierrez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ashley H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Wendy Pace",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Madeline S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Bethany Mosley",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Stephen C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Angela Mays",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christian G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Christopher Parker",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Kenneth Hudson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Maria C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "David Johns",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brent S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "James Mills",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Shelby A.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "Michael Torres",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Alyssa H.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Renee Lozano",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Troy F.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Sabrina Donovan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kevin O.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Daniel Mccormick",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michelle T.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Amanda Watkins",
        "service_type": "Garage Door Installation",
        "tech_name": "Shelby B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Terry Burton",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brittany H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Kristi Lloyd",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ronald P.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Amanda Wagner",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Deborah T.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Jamie Todd",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "David T.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Meredith Sanchez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Barbara C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Kathleen Tanner",
        "service_type": "Garage Door Repair",
        "tech_name": "Bruce F.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Amy Berry",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Michelle Figueroa",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Aaron P.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "Nicole Frost",
        "service_type": "Garage Door Repair",
        "tech_name": "Jason M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Nicholas Espinoza",
        "service_type": "Garage Door Services",
        "tech_name": "Stacey G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Frances Franco",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Heidi M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Dana Hester",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Julia P.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Stacey Lopez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joel B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Erica Taylor",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stephanie D.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Jillian Carpenter DVM",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sandra R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "David Wagner",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Samantha W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Brent Kelly",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Deanna H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Travis Harrington",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Benjamin J.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Todd Levine",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Omar C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Gregory Turner",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Teresa B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Jacob Duncan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Andrea P.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Matthew Hamilton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Edward M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Ashley Robertson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lisa K.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Patrick Sanders",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Maria B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Jose Ruiz",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kathryn C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Michelle Morris",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sherri W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Bradley Thomas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tina G.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Jeremy King",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John P.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Jennifer Stone",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michelle L.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Aaron Ellis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Roberto C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Aaron Park",
        "service_type": "Garage Door Installation",
        "tech_name": "Tonya W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Bryan Summers",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Keith A.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Anna Calhoun",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rebekah H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Michael Wagner",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Erica A.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Anthony Hudson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kelly R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "John Bauer",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tiffany G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "John Fitzgerald",
        "service_type": "Garage Door Installation",
        "tech_name": "Daniel B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Taylor Reyes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Benjamin R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Heidi Martinez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kimberly B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Randy Cook",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lisa R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Andrew Kirk",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Thomas K.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.8,
        "customer_name": "Brad Massey",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Krista P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Monica Rubio",
        "service_type": "Garage Door Insulation",
        "tech_name": "Richard W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Denise Hodges",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brian M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-14",
        "review_rate": 5.0,
        "customer_name": "Carla Weiss",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Randy L.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Catherine Castillo",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jacob S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Kristen Werner",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Leslie G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Jamie Lee",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Alison Weaver",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tyler G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Kim Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Leslie G.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Brittany Stone",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nicholas M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Jay Hill",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ellen M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Stephanie Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Olivia W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Ernest Davis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "William R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Juan Francis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Thomas B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Christopher Castro",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Maria E.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Rebekah Scott",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Henry G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Sabrina Gentry",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Connie J.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Haley Mack",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Stephanie C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "Ryan Francis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Donna B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-17",
        "review_rate": 5.0,
        "customer_name": "Angela Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ashley V.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Anthony Barnes",
        "service_type": "Garage Door Repair",
        "tech_name": "Misty M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Ashley Wong",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Roger L.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Ana Baker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Derek C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Joseph James",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Shawn H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Deborah Dalton",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alexis N.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Michael Reynolds",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Linda R.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Marcus Simmons",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Megan G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Linda Cruz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Julia N.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Bethany Jackson",
        "service_type": "Garage Door Installation",
        "tech_name": "Vickie R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Andrew Schaefer",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Erin J.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Steven Swanson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Allison T.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Glen Torres",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Steven N.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Emily Arnold",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Danielle L.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-05",
        "review_rate": 5.0,
        "customer_name": "Lisa Bauer",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jessica C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Kenneth Chen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Drew B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Bernard Wolfe",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brittany T.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Thomas Carter",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michael G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Maurice Vasquez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Bryan M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Dylan Barker",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Adam A.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Margaret Howard",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Julian P.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Nicole Sawyer",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Linda C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Wiggins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jacob H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Emily Evans",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rebekah P.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Casey Rosales",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kelsey R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Kayla Alvarez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alexandra K.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Ronald Jackson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Stephen B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Stephanie Coleman",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michael R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Melissa Haley",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Ernest Brooks",
        "service_type": "Garage Door Opener",
        "tech_name": "Wayne M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Victoria Snyder",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Scott B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Shannon Foley",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joseph C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Aaron Salazar",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christian H.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "James Hudson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jack W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Eduardo Foster",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Margaret M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Michelle Sanchez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jaclyn H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Michelle Welch",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michelle D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Mike Ross",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Thomas P.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Timothy Mitchell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tyler J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Charles Phillips",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Judith S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Sarah Cox",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Katherine O.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Melanie Morris",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brittany H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Michael Wilson",
        "service_type": "Garage Door Opener",
        "tech_name": "Jennifer T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Rebecca Lee",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Eric M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Cody Pena",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jonathan S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Sean Peters",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephanie M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Shelia Gutierrez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Thomas G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Christopher Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Claudia O.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Miranda Kirk",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Catherine W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Victoria Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "John V.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Jamie Garrison",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Timothy R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Sonya Foster",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Chris L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Daniel Vazquez",
        "service_type": "Garage Door Repair",
        "tech_name": "Ryan G.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Scott Peters",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tammy B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Maureen Gallagher",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Daniel P.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "James Hoover",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Martha T.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Tommy May",
        "service_type": "Garage Door Installation",
        "tech_name": "John H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "David Wright",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Erika R.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Jennifer Cannon",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Pamela J.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Cheryl Hutchinson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jennifer H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Kevin Sullivan",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kristopher H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Johnathan Meyer",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lucas P.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Alexandra Liu",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Dr. Brenda Murphy",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rebecca J.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Robert Stone",
        "service_type": "Garage Door Services",
        "tech_name": "Linda B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Brenda Nunez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tiffany P.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "David Mccoy",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brian C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Ronald Friedman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joshua J.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Teresa Miller",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Billy T.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Donald Munoz",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Douglas L.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Joseph Hicks",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Katrina M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Derrick Lee",
        "service_type": "Garage Door Installation",
        "tech_name": "Jonathan R.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Sara Davis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Erin A.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Timothy Perez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christopher S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Kelly Mitchell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jennifer H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Dean Owens",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Timothy M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Allison Kelly",
        "service_type": "Garage Door Off Track",
        "tech_name": "Courtney J.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Garrett Young",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Madeline M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Richard Cooper",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nicholas V.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Marcus Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jessica W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Michele Robinson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Donna B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Mary Simmons",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Frances A.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Albert Lozano",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Stacy S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Grant Hodge",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cameron H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Abigail Khan",
        "service_type": "Garage Door Repair",
        "tech_name": "Michelle W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Steven Morales",
        "service_type": "Garage Door Services",
        "tech_name": "Kelly C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Audrey Allen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Miguel J.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Carol Hughes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeffery S.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Adam Flores",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Shawn F.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "David Parrish",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Caitlyn S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Jill Mcmillan",
        "service_type": "Garage Door Repair",
        "tech_name": "Gregory F.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Cynthia Huerta",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jean A.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Lisa Torres",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicholas L.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Cynthia Ramirez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Alyssa J.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Theodore Walker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Linda R.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Katie Carter",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tanya R.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Maria Ball",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Linda S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Rickey Simpson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Luis S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Rebecca Gates",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Amy E.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Scott",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amy C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Daniel Moore",
        "service_type": "Garage Door Repair",
        "tech_name": "Kayla C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Tyler Frost",
        "service_type": "Garage Door Services",
        "tech_name": "Albert C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Christina Griffith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "John H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "James Mcdowell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Randy K.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Lucas Reyes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Austin E.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Devin Anderson",
        "service_type": "Garage Door Repair",
        "tech_name": "Clayton M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Michael Payne",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christian E.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Nathan King",
        "service_type": "Garage Door Off Track",
        "tech_name": "Charlotte P.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Diana Nelson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jacqueline L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Andrew Huffman",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Frances Ryan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mary W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Colleen Dixon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Aaron P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Amy Benitez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jerry T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Donna Ferguson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Scott A.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Cynthia Hamilton",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ariana C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Gabriella Blair",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lisa R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Henry Hughes",
        "service_type": "Garage Door Insulation",
        "tech_name": "Thomas F.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Virginia Black",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Adam T.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Danielle Duarte",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kimberly M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Dawn Erickson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Erik S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Cody Wood",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dustin T.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Mr. Timothy Norman Jr.",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Richard H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Jonathan James",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ashley S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Terri Myers",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jacqueline W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Jeffrey King",
        "service_type": "Garage Door Services",
        "tech_name": "Joann S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Douglas Thomas",
        "service_type": "Garage Door Opener",
        "tech_name": "Alyssa W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Frederick Rogers",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ryan W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Chad Braun",
        "service_type": "Garage Door Services",
        "tech_name": "Troy W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "William Sampson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Beverly G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Kristin Hayes",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jillian F.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Jordan Atkinson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Katie T.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "William Lee",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Samantha J.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Timothy Boyer",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Shelia S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Cheyenne Andrews",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Catherine D.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Crystal Thomas",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jesse V.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Catherine Price",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Candice S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Denise Patrick",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Krystal F.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Mr. Michael Suarez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lori M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Mark Ochoa",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Evan C.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Ronald Wilson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "George M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Bill Hunt",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Anthony Shaw",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael L.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "April Medina",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Wendy R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Samantha Stewart",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Erin M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Harold Woods",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mary R.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Sonya Myers",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mario A.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Mark Lewis",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Danielle H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Austin Fisher",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Glenn W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Charles Baker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lisa H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Paula Watson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "William S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Jennifer Watkins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Matthew G.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Joseph Hooper",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Robert R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Jeremy Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Randy D.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Cody Anderson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Erik K.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Veronica Thomas",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jeremy P.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Christine Cook",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer Z.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Samantha Walton",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Matthew C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Pamela Gomez",
        "service_type": "Garage Door Opener",
        "tech_name": "Brian P.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Joseph Davis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jason V.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Karen Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Paul M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Devin Gray",
        "service_type": "Garage Door Installation",
        "tech_name": "Louis Q.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Cynthia Gregory",
        "service_type": "Garage Door Opener",
        "tech_name": "Ashley B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Robin Bright",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Corey E.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Michael Ho",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Janet O.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Yvonne Cook",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jason C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Hannah Finley",
        "service_type": "Garage Door Services",
        "tech_name": "Nicole S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Taylor Hess",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "George T.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Tonya Taylor",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mark V.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Justin Davis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Virginia K.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Christian Brown",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jacob H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Matthew Martinez",
        "service_type": "Garage Door Services",
        "tech_name": "Nicole C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Catherine Cabrera",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kathryn F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Olivia Valdez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Dawn R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Donna Freeman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Melissa H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Joseph Hoffman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tanya P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Scott Crosby",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Jonathan Berry",
        "service_type": "Garage Door Services",
        "tech_name": "Diane M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Jason Castaneda",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Karen H.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Caitlin Parker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Stephanie W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Dalton Garcia",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kimberly P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Jeff Bridges",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Carol J.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Todd Garcia",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Larry W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Stacy Allen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeffrey M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Heather Arnold",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tina T.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Sara Mcdowell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Timothy N.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Kenneth Hansen",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christina B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Robert Phillips",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gina M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Michael Miller DDS",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Shawn G.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Cody James",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alexis E.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Glen Spence",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brooke S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Matthew Arnold",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Destiny O.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Rachel Tran",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lynn C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Jennifer Phillips",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Eric P.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Willie Price",
        "service_type": "Garage Door Repair",
        "tech_name": "Scott R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Andrew Huerta",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nathan C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Michelle Webb",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Mark Lynch",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephen S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Tracey Armstrong",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Casey R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Henry Todd",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Alfred R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Kathy Mcclain",
        "service_type": "Garage Door Opener",
        "tech_name": "Dana L.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Jesse Fisher",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jaclyn H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Laurie Rivas",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James T.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Julie Sanchez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Anthony G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Pamela Pratt",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jacqueline W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Nancy Herrera",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Rebecca L.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Denise Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ashley S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Mary Kelly",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Margaret Hurst",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Bryan H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Leslie Li",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amy G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Bobby Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Justin J.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Alison Archer",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Desiree M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Martin Lyons DVM",
        "service_type": "Garage Door Repair",
        "tech_name": "Jason S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Jill Hall",
        "service_type": "Garage Door Off Track",
        "tech_name": "Stephen G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Shawn Collins DDS",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kathleen J.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Jennifer West",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sylvia H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Andrew Short",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michael F.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Mrs. Holly Thompson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Manuel G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Christopher Murphy",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James F.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Douglas Roman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mary R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Bruce Buckley",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Cheyenne R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Sonya Townsend",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christina R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Michael Grant",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Shannon Gray",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jose M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Molly Carter",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Richard K.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Lisa Martinez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kenneth M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Katherine Lee",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Thomas M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-30",
        "review_rate": 5.0,
        "customer_name": "Andrew Mcbride",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tony S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "John Giles",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kevin S.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Keith Lee",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andrew D.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Michael Escobar",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Elizabeth V.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Kimberly Long",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Olivia S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Cheryl Zimmerman",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Aaron T.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Michael Kim",
        "service_type": "Garage Door Off Track",
        "tech_name": "Pamela P.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-05",
        "review_rate": 5.0,
        "customer_name": "Michael Kennedy",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michelle M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Janice Nguyen",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nathan M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "John Woods",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rachel S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Brenda Johnson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Troy J.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Kayla Martinez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sarah G.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "Madeline Powell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Angela Morris",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kristen Z.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Danielle Torres",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joseph D.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Joshua Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joshua P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Melissa Green",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Kayla Ibarra",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jacob R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Diana Riddle",
        "service_type": "Garage Door Installation",
        "tech_name": "Brenda H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "William Cooper",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kevin M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Rebecca Mills",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Angel J.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Megan Douglas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "David N.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Tina Anthony",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "John R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Virginia Parker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kathy S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Nicholas Olson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gregory R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Brandon Gonzalez",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeffrey J.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Jaime Hale",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Johnny K.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Brett Cruz",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Angela J.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Xavier Castillo",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michele L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Latasha Rodgers",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ethan S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Charles Leonard",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brian W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Heather Sullivan",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sharon B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Shannon Robinson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brian C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Tami Lee",
        "service_type": "Garage Door Off Track",
        "tech_name": "Donna M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Michael Campbell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Timothy D.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Ann Simmons",
        "service_type": "Garage Door Repair",
        "tech_name": "Rebecca B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Brian Collins MD",
        "service_type": "Garage Door Services",
        "tech_name": "Daniel P.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "James Jackson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Randy T.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Jessica Walters",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sarah D.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Randy Baker",
        "service_type": "Garage Door Services",
        "tech_name": "Stephen R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Aaron Mcdonald",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jacob G.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Claire Hoffman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mary G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Glenn Young",
        "service_type": "Garage Door Services",
        "tech_name": "Jacqueline M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Mark Lane",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Erin G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Erin Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kristi G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Andrea Pittman",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Maria B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Megan Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sheryl C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Chad Harris",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Robert P.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Brenda Moore",
        "service_type": "Garage Door Off Track",
        "tech_name": "David A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Rebecca Delgado",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Timothy Montgomery",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Eric S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Joseph Nixon",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Tommy T.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Mary Peterson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sandra V.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Joseph Pearson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Marc R.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Donna Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Catherine P.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Andrew Holmes",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jeffrey H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Timothy Harris",
        "service_type": "Garage Door Off Track",
        "tech_name": "Patricia G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Brian Gutierrez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christine P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Michele Reid",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kimberly F.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Caitlin Collins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amy B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "John Rivers",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Meredith H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Dennis Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Arthur C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Alisha Rodriguez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Stacy C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Mr. Tony Roberts",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rebecca S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Frank Valdez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nathaniel D.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Charles Suarez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Charles R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Michael Vasquez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Charles G.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Daniel Garrison",
        "service_type": "Garage Door Repair",
        "tech_name": "Kevin C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Matthew Walker",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christian W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Gerald Ochoa",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brandon F.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Tiffany Gonzalez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Stephanie S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "William Miller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Bradley T.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Eric Estes DDS",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Todd P.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Shannon Wallace",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lindsay P.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Ricky Reilly",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Maria B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Kelly Ryan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brandon M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Brittany Brown",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Frances Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jessica L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Daniel Peters",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Francisco B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Ronald Massey",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jennifer H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Anthony Gutierrez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robin W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "James Miller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Luke G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Kathy Butler DVM",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Shelby S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Mary Hughes",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "George W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.8,
        "customer_name": "Timothy Flores",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Linda S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Lisa Thompson",
        "service_type": "Garage Door Repair",
        "tech_name": "Anthony O.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Janice Ingram",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Audrey C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Danielle Schmitt",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Susan Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Raymond M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Candace Chung",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Daniel R.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Kenneth Bradley",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Melissa A.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Hannah Walsh",
        "service_type": "Garage Door Services",
        "tech_name": "Michelle B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Richard Gilmore",
        "service_type": "Garage Door Installation",
        "tech_name": "Tiffany S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Olivia Morgan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Luke S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Alex Chapman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michelle A.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Timothy Brennan",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kristina H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Thomas Moran",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Andrew C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Justin Hernandez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jeffrey G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Michael Lambert",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Paul B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Richard Hill",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sarah A.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Suzanne Edwards",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Shawn P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Melissa Hood",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cindy W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Brian Hernandez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Vincent D.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Nathan Parrish",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kathy A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Ashlee Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lance N.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Ashley Moreno",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Gregory Cox",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Dawn T.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Kristina Gibson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Erika B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Belinda Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "William J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Bethany Carter",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John A.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Tyler Hernandez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kim J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Erica Perry",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jill L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Robert Cox",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Lynn Richards",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Anthony H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Melissa Carson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Peter C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Valerie Mendoza",
        "service_type": "Garage Door Repair",
        "tech_name": "Gregory S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "Jessica Webster",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Allen N.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Matthew Marquez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ariel C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Christine Obrien",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Marc H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Scott Miller",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Erin H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Kathy Anderson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Greg T.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "John Jones",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Allison F.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Wendy Daniel",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Maria R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Catherine Cuevas",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gregory A.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Jose Walton",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amy L.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Christian Norman",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mary O.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Joseph Nelson MD",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ashley H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-02",
        "review_rate": 5.0,
        "customer_name": "Dawn Sullivan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "James O.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Henry Mayer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Angela C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Charles Cruz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joyce T.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Michael Lewis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tonya M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Cheryl Frazier",
        "service_type": "Garage Door Insulation",
        "tech_name": "Denise W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Jamie Salinas",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Carl G.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Joan Melton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joshua B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Brittany Wall",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Emily W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Stephanie Morris",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amy A.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "Brett Hawkins",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jonathan W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Jacob Walker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lindsay H.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "William Miller",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Steven Brooks",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jim T.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Jennifer Chavez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Jessica Norris",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "William C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Michael Gardner",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tracey T.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Dan Chang",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ryan B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.8,
        "customer_name": "Kimberly Rojas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher E.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Sandra Thompson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sarah S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Juan Archer",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Amy Lopez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Julia Davis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Steven M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Tyler Peterson",
        "service_type": "Garage Door Opener",
        "tech_name": "Phillip S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Dennis Kennedy",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sean C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.8,
        "customer_name": "Curtis Palmer",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Matthew R.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Heather Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Rickey W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Katherine Bryant",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James A.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Amanda Shannon",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Nicholas M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Shawn Diaz",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Marissa P.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Erica Morrow",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andrew B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Shari Mitchell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lisa R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Lisa Reyes",
        "service_type": "Garage Door Opener",
        "tech_name": "Thomas S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Heather Morales",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Thomas R.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "John Ramirez",
        "service_type": "Garage Door Installation",
        "tech_name": "Nina M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Hector Harper PhD",
        "service_type": "Garage Door Opener",
        "tech_name": "Amy M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Brian Mcdonald",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ryan H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Sarah Thompson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Craig J.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Charles Buck",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Bobby D.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Hannah Griffin",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Barry A.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Melanie Griffin",
        "service_type": "Garage Door Insulation",
        "tech_name": "John M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Darrell Dixon",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kathryn W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Lynn Santiago",
        "service_type": "Garage Door Services",
        "tech_name": "John B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Isaiah Tucker",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jennifer S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Kathryn Daniel",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ashley S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Bianca Taylor",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brianna O.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Laura Wiley",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Patricia W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Jennifer Wilson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kimberly L.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Martin Chapman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Susan J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Valerie Harrison",
        "service_type": "Garage Door Insulation",
        "tech_name": "Beth C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Eric Juarez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Douglas M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Jesse Williamson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Karen B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Patricia Sanders",
        "service_type": "Garage Door Repair",
        "tech_name": "Crystal W.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Mary Webb",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Paul D.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Paul Stone",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Connie E.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Jesse Terry",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Rachel S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Jennifer Jackson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brandon W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Denise Peters",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer P.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Bailey Yang",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gary G.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Jamie Taylor",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Tristan Rose",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Erika S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Janice Rogers",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Alexander H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Harry Gordon",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Leah L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "Kristine Fernandez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Paige W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Claire Wilson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brittney L.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Daniel Mcdonald",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Luis G.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Stephanie Hanna",
        "service_type": "Garage Door Off Track",
        "tech_name": "William A.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Terri Davis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brittany F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Jennifer Cardenas",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Madison S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Sharon Becker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Hannah B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Raymond Jenkins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joanna W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Tracey Ayala",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Daniel K.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Gary Parker",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cassandra G.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Mark Aguilar",
        "service_type": "Garage Door Opener",
        "tech_name": "Taylor H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "John Chambers",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gavin P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Joseph Bell MD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Dillon H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Sarah Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ashley C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Nicholas Hernandez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Misty J.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Maureen Johnson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nicole C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Spencer Wright",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kristin G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Zoe Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "John B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Patrick Edwards",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joel H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Sheila Mayo",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Laura B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Joshua Sullivan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sharon L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Karina Miller",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Steven C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Allison Hawkins",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrew H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Luis Potts",
        "service_type": "Garage Door Repair",
        "tech_name": "Stephen H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Robert Shea",
        "service_type": "Garage Door Repair",
        "tech_name": "Lori B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Dana Diaz",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Craig B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Heather Chambers",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gregory G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "George Stone",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Matthew B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Aaron Mendez",
        "service_type": "Garage Door Services",
        "tech_name": "Eugene L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Michael Mercado",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Charles M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Manuel Gutierrez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brian S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Tina Campbell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Matthew S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-02",
        "review_rate": 5.0,
        "customer_name": "Deanna Smith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Eric C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Allison Hernandez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Eric V.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Sabrina Woodward",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tracie C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Jennifer Roberts",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alyssa M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Chad Jimenez DVM",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lindsey M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Henry Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brian H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Robert Miller",
        "service_type": "Garage Door Opener",
        "tech_name": "Aaron M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Kent Martinez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Cheryl T.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Rebecca Moore",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Patricia H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "John Roberts",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Bradley R.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Sarah Copeland",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Paul A.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Brian Moore",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kathleen T.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Scott Mendoza",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Diana A.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Joe Jackson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amy B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Brittany May",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Don L.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Linda Hood",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Anna L.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Devin Carr",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Scott B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Charles Snyder",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Matthew W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Ashley Gibbs",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "William Haas",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Hannah S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Robin Butler",
        "service_type": "Garage Door Opener",
        "tech_name": "Heather H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Richard Clark",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Devin A.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Christopher Escobar",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Cynthia D.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Paul Anderson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lisa F.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Heather Koch",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jamie S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Scott Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tammy M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Amber Porter",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Eric A.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Daniel Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Veronica H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Sarah Diaz",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brittany P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Louis Walsh",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Latoya S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-28",
        "review_rate": 5.0,
        "customer_name": "Matthew Young",
        "service_type": "Garage Door Repair",
        "tech_name": "Jean T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Susan Scott",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kayla M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Megan Wilson",
        "service_type": "Garage Door Services",
        "tech_name": "Marcus A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Alison Gilbert",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Troy B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Kelli Evans",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jerry L.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Kimberly Harris",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Linda G.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Jacob Ward",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeffrey S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Joseph Torres",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sonia L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Jennifer Taylor",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Allison E.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Bradley Mullen",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Terri W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Paula Parrish",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Taylor F.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Terri Carrillo",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ryan L.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Peggy Garcia",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tammy C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Wendy Christensen",
        "service_type": "Garage Door Opener",
        "tech_name": "Rachel R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Michael Horne",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michelle R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Monique Garrett",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Teresa W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Yvette Miller",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephen M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "James Rogers",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "April C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Karen Black",
        "service_type": "Garage Door Repair",
        "tech_name": "Timothy W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Jeff Hicks",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mary M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Cindy Foley",
        "service_type": "Garage Door Services",
        "tech_name": "Maria H.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Kelly Lopez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Emily R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Melanie Ramos",
        "service_type": "Garage Door Off Track",
        "tech_name": "Cameron R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Jason Olson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jason M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Madison Blackwell DDS",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Katherine W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Tiffany Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stephanie M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Jennifer Gill",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joseph J.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Tony Monroe",
        "service_type": "Garage Door Insulation",
        "tech_name": "Rebecca J.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Brittany Daniel",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Rebecca H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Karen Mays",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lisa M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Judy Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Renee W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Jeanne Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Alex W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Darlene Ingram",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amanda C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-25",
        "review_rate": 5.0,
        "customer_name": "Amber Smith",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Daniel L.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Mrs. Pamela Marshall DDS",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amy F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Denise Stone",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Maria G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "William Douglas",
        "service_type": "Garage Door Opener",
        "tech_name": "Elizabeth C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Mr. Donald Ward",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Katherine W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Travis Harris",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "David M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "David Garcia",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brenda D.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Brittany Warner",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Mark Flores",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ruben F.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-05",
        "review_rate": 5.0,
        "customer_name": "Jason Stone",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Annette C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Tanya Harper",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Ricardo Griffin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Matthew P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Christopher Gonzalez",
        "service_type": "Garage Door Services",
        "tech_name": "Joshua D.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Thomas Fowler",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Megan F.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Thomas Jackson",
        "service_type": "Garage Door Opener",
        "tech_name": "Dennis R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Chelsey Paul",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tracy W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Aaron Brooks",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Chelsea D.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Tiffany Lopez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Savannah Vargas",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Javier P.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Billy Sullivan",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Randy J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Kevin Hays",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jeffery S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Vanessa Barr",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Allen J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Miss Julie Terry DDS",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Justin M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Phillip May",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ronald L.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Robert Gonzales",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Beth M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Angela Rice",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Melissa S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Tara Wells",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michael H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Daniel Knox",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Matthew T.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Stephanie Marks",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jane B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Sandra Horne",
        "service_type": "Garage Door Off Track",
        "tech_name": "Carly J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Michael Savage",
        "service_type": "Garage Door Installation",
        "tech_name": "Morgan F.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Jesse Hernandez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kathryn F.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Dennis Ellison",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Patrick C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Michelle Lopez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ruth W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Christopher Davis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Richard T.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Christopher Gibson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Eric C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Mitchell Morgan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mary W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Anthony Gaines",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Nicholas Bright",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Shelly J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Sabrina Bowman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Danny B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Rachael Roberts",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lacey M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Brianna Craig",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brian C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Christopher Gonzalez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stephen K.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Gina Long",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Belinda S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Cheryl Jones",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Emily O.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Mrs. Desiree Mays MD",
        "service_type": "Garage Door Opener",
        "tech_name": "William M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Todd Rodriguez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Shari J.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Yolanda Young",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stephen G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Lonnie Torres",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Deanna M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "Carla Blankenship",
        "service_type": "Garage Door Installation",
        "tech_name": "Terry M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Dalton Cruz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Logan G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Emily Roberts",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sylvia L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Jacob Osborn",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Caitlin E.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-13",
        "review_rate": 5.0,
        "customer_name": "Robert Vargas",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Natalie N.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Samantha Lynch",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Barbara R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Miguel Jimenez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Donald R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Julie Braun",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Christopher Golden",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Richard D.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Craig Foster",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Eric J.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "April Brown",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gary G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Jeremiah Taylor",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Andrew R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Tanya Mccall",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amanda C.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Laura Evans",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael L.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Michael Bridges",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jennifer R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Kayla Gonzalez",
        "service_type": "Garage Door Installation",
        "tech_name": "Veronica S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Steven King",
        "service_type": "Garage Door Services",
        "tech_name": "Christopher R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Alexander Velez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jill C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Kyle Jones",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Scott W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Denise Barker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Beverly M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Darlene Harmon",
        "service_type": "Garage Door Repair",
        "tech_name": "Joseph S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Olivia Curry",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tiffany G.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Edgar Yang",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Scott F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Ronald Rangel",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mary O.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Daniel Gray",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Chloe C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Michelle Thompson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Chad H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Ann Everett",
        "service_type": "Garage Door Opener",
        "tech_name": "Natalie N.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Grace King",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Olivia G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Alexander Cook",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Scott P.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Jacqueline Lee",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Scott W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Dr. Patty Lewis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Andrea B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Amy Barrera",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Samantha W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Victor Garcia",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Troy B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Tracey Martinez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Stacy S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Steven Molina",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "John C.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Donna Meadows",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Caroline L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Martinez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Garrett G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Katelyn Underwood",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Scott T.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Reeves",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Thomas O.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Johnathan Mcgee",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joshua F.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Bonilla",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Bradley J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Troy Flowers",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert D.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Michael Gibson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Roger H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Mr. Kevin Snow MD",
        "service_type": "Garage Door Opener",
        "tech_name": "Ryan B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Brianna Gordon",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "John R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Kathleen Cowan",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cynthia C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Alexandra Burgess",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Robert V.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Wendy Foster",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tina G.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Troy Carroll",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jessica B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Lori Fitzgerald",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sandra G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Shirley Perez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Patricia M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Peters",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James A.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Lucas Green",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Erica R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Kelly Barker",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Courtney B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Tina Stewart",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Scott M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Alexis Jacobs",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jacqueline B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Johnathan Graves",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Patricia S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Diana Fleming",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ernest Y.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Kathryn Owens",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christina S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "Robin Thomas",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Todd G.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Anthony George",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Eric S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Diana Rose",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kimberly G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Sydney Wallace",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "David L.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Jaclyn Thomas",
        "service_type": "Garage Door Repair",
        "tech_name": "Rebecca B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Jennifer Cordova",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Susan M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "William Stephens",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "James H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Daniel Brown",
        "service_type": "Garage Door Services",
        "tech_name": "Jeremy A.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Samantha Cisneros",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kenneth T.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Dr. Amy Aguirre",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Erica B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Cynthia Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jasmine H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "John Miller",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa D.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Rodney Snyder",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicole R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Trevor Dean",
        "service_type": "Garage Door Off Track",
        "tech_name": "Melvin M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Mary Johnston",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kyle T.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Bobby Jacobs",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Barbara H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Debbie Lopez",
        "service_type": "Garage Door Repair",
        "tech_name": "Mary S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Steven Lewis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gregory G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Sara Austin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Eric F.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "John Velasquez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Patrick M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Kathryn Strickland",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kimberly M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Kayla Henry",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lawrence H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Dr. William Scott",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Penny H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Michael Jimenez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Daniel S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Candice Garcia",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Parker M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Michael Buckley",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Madison W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "Judy Fox",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rose B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Jonathan Lee",
        "service_type": "Garage Door Services",
        "tech_name": "Robert M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Lori Conway",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Stephanie Walker",
        "service_type": "Garage Door Off Track",
        "tech_name": "William S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Christopher Thomas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jamie Z.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Christopher Jones",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tara D.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Kathleen Howard",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joshua B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Amber Franklin",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Howard T.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "David Santos",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jessica D.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Steven Washington",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Marilyn J.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Sydney Miller",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christina M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Christopher Walters",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Jose Singleton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Regina J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "John Zuniga",
        "service_type": "Garage Door Opener",
        "tech_name": "Grace S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Sarah Mitchell DDS",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nancy S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Kristen Davis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Robert B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Dominic Williams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William D.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "Ashley Glover",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anthony S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Regina Mclaughlin",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christine L.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Bryan Gibson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Tara J.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Andrew Wright",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Stephanie C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "James Wolfe",
        "service_type": "Garage Door Installation",
        "tech_name": "Veronica C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Samuel Adams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Robert H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Daniel Phillips",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Clinton S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Anna Howell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ariana R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Dennis Sloan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Timothy N.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Aaron Anderson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Steven A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Roy Mcdonald",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jeremy G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Bill Williams",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "David L.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "David Kent",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John O.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Matthew Fernandez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lynn P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Carolyn Washington",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Dana R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Rebecca Mathews",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lindsey H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Rebecca Garner",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amy M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Richard Zhang",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Susan M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Ashley Miller MD",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Drew Sandoval",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Karen W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Melissa Collins",
        "service_type": "Garage Door Services",
        "tech_name": "Elizabeth G.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Kimberly Lang",
        "service_type": "Garage Door Repair",
        "tech_name": "Elizabeth G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Ashley Nicholson",
        "service_type": "Garage Door Insulation",
        "tech_name": "William W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Richard Jordan",
        "service_type": "Garage Door Repair",
        "tech_name": "Brenda H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "John English",
        "service_type": "Garage Door Opener",
        "tech_name": "Dennis B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "William Trevino",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Allison M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Frances Gordon",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Roy D.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Sarah Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nancy M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Brandon Cook",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jacqueline M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Michael Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Megan J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Kaitlyn Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Juan M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Ana Bryan",
        "service_type": "Garage Door Repair",
        "tech_name": "Andrea R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Nicholas Sawyer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ian R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Lauren Mosley",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Courtney M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Victoria Richards",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jose V.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Brett Williams",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sean N.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Robert Montoya",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Angela W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-17",
        "review_rate": 5.0,
        "customer_name": "David Buckley",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ronald B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Aimee Ray",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jessica M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Carolyn Lane",
        "service_type": "Garage Door Repair",
        "tech_name": "Jacob G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Tyler Rodriguez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Stephanie W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Robert Padilla",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Derek A.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "James Mitchell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Rebecca M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "David Berg",
        "service_type": "Garage Door Repair",
        "tech_name": "Dana P.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Laura Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brittany R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Miguel Clark",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kim J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Brian Obrien",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christopher F.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Kelly Rogers",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lucas F.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Amy Malone",
        "service_type": "Garage Door Opener",
        "tech_name": "Gabriel H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Danielle Gordon",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Robert R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Megan Shaw",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jennifer L.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Christopher King",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Hector B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Brandon Garner",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nathaniel M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Tony Singh",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "James L.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Tracy Willis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Theresa C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Bill Hughes",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Melanie W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Heather Underwood",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stephen R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Joseph Sharp",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Leon W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Yolanda Neal",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Vincent F.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Christina Turner",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Erica P.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Anne Good",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Eric R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Melvin Reyes",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kylie T.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Hayden Gray",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Steven D.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Jordan Hancock",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John L.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Ryan Blackburn",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gary C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Erik Palmer",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Colton J.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Anthony Castro",
        "service_type": "Garage Door Services",
        "tech_name": "Jill A.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Erica Lopez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Christina Davis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Thomas B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Katherine Stone",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Patricia W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Jacob Evans",
        "service_type": "Garage Door Repair",
        "tech_name": "Carolyn B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Mary West",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "William R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Latoya Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Taylor T.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Thomas Wright",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christopher A.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "John Cooper",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gabriella C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Ashley Young",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Shaun J.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Nicholas Hardy",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Eric R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Randall Roberts",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kaitlyn P.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Daniel Cooper DVM",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Arthur L.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Johnathan Fuller",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Eric S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Daniel Morales",
        "service_type": "Garage Door Repair",
        "tech_name": "James W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Lisa Norris",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Rodney V.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Tommy Harris",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kristine G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "David Carter",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Morgan Martinez",
        "service_type": "Garage Door Services",
        "tech_name": "Megan K.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Heather Barnes",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joseph M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Michele Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kelly N.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Michael Gomez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Barry M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Gregory Hood",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brian O.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Julie Odonnell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ruth R.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Brittany Gonzalez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Richard M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Charles White",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Paul R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Jenny Wolfe",
        "service_type": "Garage Door Repair",
        "tech_name": "Judy M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Amy Pierce",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kimberly D.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Matthew Walker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brittany W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Kimberly Jackson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jenny M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Kristy Mendoza",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Todd C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Beth Rodriguez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeffrey F.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Jon Martinez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nicole L.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Melinda Gonzalez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert N.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Matthew Walker",
        "service_type": "Garage Door Repair",
        "tech_name": "Jasmine M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Johnathan Anderson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nicole M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Randy Torres",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sharon E.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Stephanie Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jill M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Brian Ruiz",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Karen W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.8,
        "customer_name": "Jessica Graham",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Thomas M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Amanda Parks",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kara O.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Dr. Laurie Clark DDS",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Heather M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Charles Perry",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Marilyn B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Anna Rodgers",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alicia P.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Donna Lang",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brian M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Christopher Rice",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Charles W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Matthew Cummings DVM",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ryan L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Vincent Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joseph C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Philip Casey",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Elizabeth B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Lauren Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tyler J.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Alison Perez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anthony S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Amanda Calderon",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Elizabeth S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Tara Bishop",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David F.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Larry Price",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jordan W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "James Wheeler",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Jordan Rogers",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Justin R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Dr. Edward Perez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Juan B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Erik Farley",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Gerald T.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Kathryn Woodard",
        "service_type": "Garage Door Repair",
        "tech_name": "Andrea R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Kevin Morris",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kiara D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Kevin Cruz",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Fernando B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Carolyn Nash",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Edward D.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Glenn Robinson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lisa M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Darrell Griffin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Erik C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Tanya Cox",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jacob B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "John Weaver",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Whitney J.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Alexandria Ingram",
        "service_type": "Garage Door Insulation",
        "tech_name": "Gerald L.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Adriana Russo",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Caroline A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Michele Wang",
        "service_type": "Garage Door Installation",
        "tech_name": "Mackenzie M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Mary Martin",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Jessica Harris",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Johnny J.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Deborah Davidson",
        "service_type": "Garage Door Installation",
        "tech_name": "Ralph S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Christopher Mason",
        "service_type": "Garage Door Opener",
        "tech_name": "Victoria R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Teresa Erickson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brittany B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Jason Sawyer",
        "service_type": "Garage Door Installation",
        "tech_name": "Arthur L.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Beth Grant",
        "service_type": "Garage Door Repair",
        "tech_name": "Oscar R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Mark Nichols",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christine L.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Dr. Julie Robinson MD",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Tiffany Stewart",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Willie B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Brittany Nash",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Reginald C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Luis Lynch",
        "service_type": "Custom Garage Door Design",
        "tech_name": "David A.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Jennifer Carter",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Nathan Dean",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Melinda F.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Tommy Lowe",
        "service_type": "Garage Door Insulation",
        "tech_name": "Laura B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-13",
        "review_rate": 5.0,
        "customer_name": "Natalie Ramirez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Miranda S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Kimberly Deleon",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kristina C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Karen White",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Dana K.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Eileen Barnett",
        "service_type": "Garage Door Installation",
        "tech_name": "Katherine M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.8,
        "customer_name": "Adam Winters",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Antonio H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Carla Hill",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Susan S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Anthony Henderson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Devin P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Joseph Becker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sara K.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Paula Gray",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael V.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Wendy Foster",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Derek M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Christian Brown",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Shawn S.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Patrick Hernandez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Carlos W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Billy Brady",
        "service_type": "Garage Door Repair",
        "tech_name": "Matthew S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Tiffany Larson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Timothy M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Mr. Jeffrey Pearson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jonathan P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Heather Miller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sandra N.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Matthew Hanson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jamie L.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Sandra Ramos",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Erin W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Lisa Cummings",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mary V.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Terri Miller",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Wendy M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Robert Rodriguez",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Brandon Bell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jillian G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Robin Romero DDS",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Vincent J.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Michael Romero",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gregory J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Barbara Palmer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Shannon F.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Robert Allison",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Candice G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Robert Elliott",
        "service_type": "Garage Door Opener",
        "tech_name": "Sarah A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Kelly Hebert",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Micheal Thompson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lawrence H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Jim Peterson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Julie G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Patricia Hall",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christina H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Karen Jones",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jaime L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Eric Garcia",
        "service_type": "Garage Door Services",
        "tech_name": "Wanda L.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Manuel Cole",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gina P.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Benjamin Fuller",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mark M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Tristan Wallace",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amber B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Gregory Fisher",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kimberly C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Maria Wiggins",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Charlotte W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "Sara Kelly",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christine B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Dale Skinner",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Janet F.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "William Sandoval",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sally W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Caroline Mccullough",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tara L.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Mary Foster",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Thomas A.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Melissa Morgan",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tamara S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Christopher Harrell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christine S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Julie Mcdonald",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Edward W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "Matthew Harrison",
        "service_type": "Garage Door Repair",
        "tech_name": "Lisa T.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Mary Hanson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Colleen Y.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Gabrielle Mills",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christine G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Matthew Bates",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Patrick F.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Kimberly Hernandez DDS",
        "service_type": "Garage Door Opener",
        "tech_name": "Nathaniel M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Crystal Green",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Courtney J.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Mr. Michael Taylor",
        "service_type": "Garage Door Services",
        "tech_name": "Jeff S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Christopher Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Judy F.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Leah Gomez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stephanie P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "Rhonda Perez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sherry W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Laura Powell",
        "service_type": "Garage Door Installation",
        "tech_name": "James R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Kendra Cooke",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ryan W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Stephanie Hall",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brian L.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Mr. Jack Day MD",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michele W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Ashley Thomas",
        "service_type": "Garage Door Services",
        "tech_name": "Sarah S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Sara Lewis",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Rebecca N.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Michael Mitchell",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Linda S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "John Blackburn",
        "service_type": "Garage Door Installation",
        "tech_name": "Lauren H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.8,
        "customer_name": "Steven Padilla",
        "service_type": "Garage Door Opener",
        "tech_name": "Bradley V.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.8,
        "customer_name": "Cathy Stevens",
        "service_type": "Garage Door Installation",
        "tech_name": "Renee M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Travis Mccarthy",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tyler L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Susan Howard",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Angela M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Sara Black",
        "service_type": "Garage Door Repair",
        "tech_name": "Amber M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Jasmin Moreno",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Zachary S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Renee Martin",
        "service_type": "Garage Door Repair",
        "tech_name": "Mark P.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Joseph Barnes",
        "service_type": "Garage Door Repair",
        "tech_name": "Blake S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Brandon Gill",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gregory S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Jenna Williams",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Alex Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Judy S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "David Cohen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brett W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Hawkins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Phillip A.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Cassandra Scott",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Valerie T.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Jennifer Sims",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amanda R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Mr. Robert Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michelle P.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Cody Castillo",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ashley M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Anne Harris",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeffrey J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Tiffany Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Terri W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Justin Cantrell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christine F.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Mary Estrada",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Emily V.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Tiffany Vega DVM",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Valerie P.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "John King",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Charles L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Chad Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Phillip V.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Julia Gill",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "David M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Leslie Bell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Timothy D.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Danielle Jackson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Cameron N.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Brian Brown",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Patricia M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Sabrina Young",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Justin D.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Isaiah Guerrero",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Suzanne D.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Katie King",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica E.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Mrs. Tracey Murray DVM",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sydney F.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Derek Wallace",
        "service_type": "Garage Door Services",
        "tech_name": "Arthur F.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Thomas Harmon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Donald F.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Kayla Hernandez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Robin P.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Steven Christensen",
        "service_type": "Garage Door Opener",
        "tech_name": "Peggy W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Mark Bartlett",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lisa G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "John Lee",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Maureen D.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Savannah Dickson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Adam S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Seth Clark",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Randy M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Mr. Caleb Woods",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kimberly E.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Dr. Charles Krueger",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nathaniel H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Michele Alvarez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrew R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Aaron Harvey",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lynn S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Sara Martin",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Marisa S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Briana Rivera",
        "service_type": "Garage Door Opener",
        "tech_name": "Kristen E.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-08",
        "review_rate": 5.0,
        "customer_name": "Kimberly Bell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Leslie K.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "James Rodriguez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Elizabeth K.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Jay Boyd",
        "service_type": "Garage Door Off Track",
        "tech_name": "Charles D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Timothy Sweeney",
        "service_type": "Garage Door Off Track",
        "tech_name": "Donald R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Jessica Stanley",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Shannon M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Reginald Mcconnell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tanya G.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Aaron Underwood",
        "service_type": "Garage Door Opener",
        "tech_name": "Debbie V.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Ruben Woods",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Erica B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "David Villarreal",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sean W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Danielle Craig",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Donna O.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Noah Cantu",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kenneth T.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Kathy Williams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jillian S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Christian Armstrong",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ronald N.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "David Owens",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Brandon Robinson II",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Evan Moore",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "John M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Brittany Goodman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Stephen A.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Denise Johnston",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michelle A.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Mary Stevens",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Victoria V.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Krystal Weaver",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Nicolas A.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Shawn Lee",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Matthew T.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Mr. Francis Wheeler",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Steven H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Brian Fields",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Paul G.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Sonya Jones",
        "service_type": "Garage Door Installation",
        "tech_name": "Julie G.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Joshua Edwards",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jacob J.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Kevin Gonzalez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "James F.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Jason Frazier",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Patricia L.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Jonathan Kim",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shannon M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Karen Morris",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Sandra Pugh",
        "service_type": "Garage Door Insulation",
        "tech_name": "Patty G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Mary Nicholson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nicholas M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Allison Adkins",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Julie B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Joshua Chandler",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "James G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Jaime Clarke",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Carol M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Kenneth Carroll",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Deborah L.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Anthony Townsend",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael T.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Lawrence Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Edward A.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Sean Reeves",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jennifer O.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Edward Hoffman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Elizabeth R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Patrick Brown",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Stephen S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Brian Lynch",
        "service_type": "Garage Door Services",
        "tech_name": "Melissa J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Timothy Miller",
        "service_type": "Garage Door Off Track",
        "tech_name": "Julian M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Mike Castillo",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sandra C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Larry Ibarra",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Vickie G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Kenneth Jones",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carla C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Kelsey Powell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sandra F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Leah Levy",
        "service_type": "Garage Door Insulation",
        "tech_name": "Corey J.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Amanda Wallace",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Patricia L.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Danny Lopez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Bobby R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Pamela Marsh",
        "service_type": "Garage Door Repair",
        "tech_name": "Hannah N.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Christina Young",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Eugene H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Dylan Kennedy",
        "service_type": "Garage Door Installation",
        "tech_name": "Michelle O.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Christopher Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Justin M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Jacob Brady",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Susan B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Christina Herrera",
        "service_type": "Garage Door Installation",
        "tech_name": "Craig E.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Brandon Mason",
        "service_type": "Custom Garage Door Design",
        "tech_name": "April R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Sharon Patterson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Grace C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Ann Davis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tyler R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Terri Mccarthy",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Connie J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Gerald Brown",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Sandra Ross",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "David Boyd",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Dennis L.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Tamara Rose",
        "service_type": "Residential Garage Door Services",
        "tech_name": "James C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Xavier Horton",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mary M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "William Newman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sydney J.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Andrea White",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Pamela M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Cody Gallegos",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Shane W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "David Hunter",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ashley M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Gregory Shepherd",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Donna T.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Paul George",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Paul P.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Robert Black",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Rebecca M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Robert Lutz",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tiffany G.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Shawn Hernandez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Heather W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Kim Shepherd",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Erica D.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Davidson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brian O.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Sandra Phillips",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "David W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Erica Miller",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Travis H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Brittany Barnes",
        "service_type": "Garage Door Installation",
        "tech_name": "Marissa S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Jasmine Beck",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Luke R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Brittany Riley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sara K.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Christina Roberts",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Raymond A.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Sandra Best",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Charles B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Anthony",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "David E.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Jennifer Russell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Daniel M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Javier Dean",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Thomas A.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Shelly Quinn",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mark G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Taylor Adams",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ryan W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Nicole Johnson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andre W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Katherine Novak",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jerry F.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Angela Williams",
        "service_type": "Garage Door Insulation",
        "tech_name": "Juan C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Theodore Acosta",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Anthony M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Shannon Morgan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Elizabeth M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Tracey Chapman",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jessica V.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Arthur Foley MD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David A.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Gabriella Morales",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Stephen W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Vincent Greene",
        "service_type": "Garage Door Repair",
        "tech_name": "Cody M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "William Ponce",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Timothy H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "Kathleen Davis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lori H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Matthew Duncan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kathleen L.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Alicia Horne",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jeanette P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Gary Stout",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christopher B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Robert Burns",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "David L.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Michael Reynolds",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Gina Parker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Scott O.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Joseph Matthews",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John L.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Nicholas Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Rebecca R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Connie Romero",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Heather C.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Jimmy Arnold",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Edward Cole",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Steven T.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Jimmy Ray",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Julia H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Brenda Adams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kathy J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Summer Barber",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michele L.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Julia Brooks",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Devin J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Christopher Roth",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Emily E.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Victoria Wilson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Melissa M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "David Lynch",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lucas G.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Richard Grimes",
        "service_type": "Garage Door Installation",
        "tech_name": "Derrick A.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Richard Anderson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kyle R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Ashley Franklin",
        "service_type": "Garage Door Opener",
        "tech_name": "Jennifer R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Brian Fernandez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Melissa B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Megan Zamora",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael L.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Adam Marquez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Amy Wang",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Olivia M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Dalton Reynolds",
        "service_type": "Garage Door Repair",
        "tech_name": "Christopher M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Madison Watson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Meagan A.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Samuel Moyer",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Veronica C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Vickie Robinson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kyle W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Madison Nelson MD",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Victoria J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Blake Brown",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jared H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Hardy",
        "service_type": "Garage Door Repair",
        "tech_name": "Jessica C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Lisa Robinson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Carlos P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Colleen Herrera",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Beth Cherry",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Whitney M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Amy Anderson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Taylor H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Larry Hernandez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Carl W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Daniel Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Matthew F.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Katherine Harris",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lisa B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Nichole Jackson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kevin D.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Phillip Long",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Steven W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-28",
        "review_rate": 5.0,
        "customer_name": "Cynthia Kelly",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anthony H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Suzanne Dixon",
        "service_type": "Garage Door Installation",
        "tech_name": "Dana V.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Melinda Ramirez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Charles Y.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Joseph Reilly",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sarah W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Joshua Cooley",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brian M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Duane Stephens",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Alyssa C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Adrian Hall",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jay R.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "John Howell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Steven A.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Kathy Middleton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Matthew A.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "James Dixon",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Linda P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Evelyn Thomas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "David L.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Lisa Miller",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sarah R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Kyle Waters PhD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Fernando R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Robert Fisher",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alyssa N.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Mr. Stephen Wiggins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Chad G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Raymond Curtis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Matthew L.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "Samantha Martinez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Megan I.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Melissa Taylor",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Margaret M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Mark Owens",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Andrew M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Dawn Gray",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jeanette C.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Frank Golden",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ian H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Jimmy Pena",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Blake J.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Joshua Cortez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Edward S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Paul Reynolds",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kevin V.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Andrew Ashley",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christopher J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Cheyenne Wood",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Larry R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Daniel Cooley",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Robert Hernandez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Keith W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-19",
        "review_rate": 5.0,
        "customer_name": "Jared Stewart",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "James S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Kendra Cohen",
        "service_type": "Garage Door Services",
        "tech_name": "Tamara V.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Kathryn Bennett",
        "service_type": "Garage Door Off Track",
        "tech_name": "Margaret G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "John Parks",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Susan W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Joshua Bond",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jessica T.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Glenn Rice",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Leon C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Laura Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Alice H.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "James Flynn",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jeffrey A.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Bradley Deleon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Misty H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "Erin Berry",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brad M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Natasha Summers",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Heather Perez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ann S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Vincent Young",
        "service_type": "Garage Door Opener",
        "tech_name": "Ryan W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Marcus Cox",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "William D.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Isabel Rivera",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kevin R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Phillip Garza",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stacey H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Patty Potter",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Matthew J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-01",
        "review_rate": 5.0,
        "customer_name": "Dennis Castillo",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jennifer L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Colleen Garcia",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tyler H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Chelsea Greene",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephanie M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Daniel Mcguire",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Daniel J.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Steven Turner",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ralph D.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Bryan Rowland",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mary W.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Dawn Hall",
        "service_type": "Garage Door Services",
        "tech_name": "Kim H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Robert Thornton",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Emily J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Alison Richardson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mary A.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Kellie Andrews",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Larry B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Jacob Tran",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Dustin M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Alexander Mercer",
        "service_type": "Garage Door Services",
        "tech_name": "Phillip S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Alex Gonzalez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Linda K.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Tracy Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michaela M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Danielle Conrad",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jeffrey W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Kayla Diaz",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Julie B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-05",
        "review_rate": 5.0,
        "customer_name": "Karen Fuller",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brad C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Jordan Mccall",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jessica M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Mr. Mike Brown",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Barbara G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Jonathan Fernandez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ann C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Wendy Campbell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nicole O.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Kristen Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "April R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Scott Perez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michelle S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Craig Rosario",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Noah C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Diana Thompson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tony B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Christopher Sandoval",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Thomas W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Susan Brown",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Whitney A.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Cynthia Hart",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Paula C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Samuel Stevens",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Melanie R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Kevin Young",
        "service_type": "Garage Door Opener",
        "tech_name": "Destiny R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Dr. Jamie Mason",
        "service_type": "Garage Door Opener",
        "tech_name": "Peter W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Anthony Mendoza",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sharon W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Marc Williamson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Linda K.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Barbara Compton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Todd H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Daniel Barr",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Antonio S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "Nicole Hall",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "John H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Bruce Watkins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Frank D.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Ryan Huber",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tiffany C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Stephen Suarez",
        "service_type": "Garage Door Services",
        "tech_name": "Emma M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Steven Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ashley B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Aaron Anderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amy L.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Ronald Whitaker",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Becky G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Javier Blankenship",
        "service_type": "Garage Door Opener",
        "tech_name": "Janet D.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Paul Johnson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Henry Villarreal",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Margaret C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Megan Obrien",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Wayne G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Jeff Patterson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ricky J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Nicole Clark",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Daniel L.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Maurice Andrews",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Adam B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Scott Doyle",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Clifford A.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Jerome James",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christian P.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Sonya Clayton",
        "service_type": "Garage Door Repair",
        "tech_name": "Steven C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Melanie Walker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jose B.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Gary Hill",
        "service_type": "Garage Door Opener",
        "tech_name": "Christina D.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Hart",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jennifer C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Mary Good",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kathleen G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Brian Rodriguez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michele B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Robert Luna",
        "service_type": "Garage Door Services",
        "tech_name": "Deborah A.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Donna Lane",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "David J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Amanda Soto",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Russell G.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Kim",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Danny H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Madison Perez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Alicia O.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Rachel Brooks",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicholas H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Colleen Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Natasha H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Todd Campbell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Bethany M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Beth Blake",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amber M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Keith Crawford",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Shannon P.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Duane Harrison",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Samuel S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Lee Evans",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Terri R.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Catherine Poole",
        "service_type": "Garage Door Repair",
        "tech_name": "Brian W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Linda Gonzales",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kevin R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Kathryn Mendoza",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brian L.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Mary Barry",
        "service_type": "Garage Door Installation",
        "tech_name": "Linda B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Elijah Burke",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Patrick H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Sarah Page",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Barbara S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Brian Carter",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Barbara M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "Matthew Myers",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John L.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Karen Watson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Matthew S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Bartlett",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Vickie L.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Lauren Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael N.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Levi Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Kathryn H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Samuel Lee",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amber P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Amber Lewis",
        "service_type": "Garage Door Opener",
        "tech_name": "Tyler P.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Denise Jenkins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brendan K.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Matthew Mata",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jason L.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Brian Randall",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "William B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Frank Bennett",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michelle A.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Alicia Davis",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert G.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Melissa Chang",
        "service_type": "Garage Door Off Track",
        "tech_name": "Justin G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Hannah Stewart",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sandra G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Nicole Zimmerman",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Casey J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Jordan Thomas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jessica O.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Daniel Carter",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kyle M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Patricia Cox",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicole W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Patrick Moyer",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Megan H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Cassandra Gilbert",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Emily L.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Mr. Ronald Brock MD",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mary M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Michael Banks",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Anita O.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Drew Madden",
        "service_type": "Garage Door Installation",
        "tech_name": "Tracy F.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Shannon Bennett",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Eric A.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Jared Herrera",
        "service_type": "Garage Door Opener",
        "tech_name": "Ralph M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Leslie Beasley",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeremy R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Ana Wilson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Patricia Y.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Dr. Betty Rodriguez DDS",
        "service_type": "Garage Door Installation",
        "tech_name": "Mary R.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Jacob Davis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kathleen M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Joel Gordon",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nathan A.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Richard Powers",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Emma K.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Ashley Wallace",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brian G.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Whitney Burgess",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jessica A.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Kelly Wade",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Allen R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Brandon Garcia",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Phillip N.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Kelly Burton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Linda D.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Amanda Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Misty Bennett",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Thomas L.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Jennifer Salazar",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tamara C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Kevin Hall",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael K.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Ashley Chavez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kathy B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Alicia Murphy",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kimberly G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Eric Escobar",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sonia P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Stephanie Cuevas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Todd H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "William Lucas",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Samantha F.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Jerry Ford",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stephanie P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Eric Cannon",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sherri B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Nathan Burton",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Harold G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Pamela Peterson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sara W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Jordan Edwards",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Krystal W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Brenda Villanueva",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Devon K.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Alexander George",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joshua G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Alexandra Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Stefanie H.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Angela Bright",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mark F.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Kelsey Williams",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Shelly A.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Dustin Wright",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kimberly S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Bethany Cross",
        "service_type": "Garage Door Repair",
        "tech_name": "Amanda H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Cassandra Gonzalez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Paul B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Michael Mccoy",
        "service_type": "Garage Door Services",
        "tech_name": "Veronica H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Shawn Garcia",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rachel I.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Brandon Castillo",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jennifer S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Cameron Brown",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kevin H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Barry Clark",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Andrea L.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Bill Zuniga",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kathryn G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Jasmine Neal",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Matthew G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Jennifer Knight",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Melinda M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Emily Walker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Andrew R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Kelsey Mcbride",
        "service_type": "Garage Door Opener",
        "tech_name": "David M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Patrick Thomas",
        "service_type": "Garage Door Installation",
        "tech_name": "Trevor A.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Jonathan Parker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cory S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Brooke Baldwin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Amanda W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Erica Hughes",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jeffrey R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Tina Ferguson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christopher O.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Dr. Calvin Jones",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Amy T.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "James Parker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Carmen S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Anthony Foley",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael R.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Eric Sanders",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joshua O.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Charles Copeland",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brian M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Cody Gordon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jo C.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Julie Klein",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Thomas C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Paul Howard",
        "service_type": "Garage Door Installation",
        "tech_name": "Mary A.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Denise Myers",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jeremy R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Donna Clark",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kelly R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "Gary Martin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alicia B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Emily Goodman",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kristi J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Heather Fleming",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sara R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "James Vaughn",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sergio R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Michael Archer",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Angel S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Brandon Lewis",
        "service_type": "Garage Door Services",
        "tech_name": "Michelle S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Steven Hill",
        "service_type": "Garage Door Off Track",
        "tech_name": "Devin S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Ashley Oneill MD",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Michaela Martinez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Crystal M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Jason Young",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Connie N.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Scott Flores",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael G.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Kelly Rhodes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "James D.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-10",
        "review_rate": 5.0,
        "customer_name": "Samantha Parker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Clifford R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Debra Gonzales",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Peter E.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Melissa Miller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christopher C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Deanna Lin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jason H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "George Rogers",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Tiffany Mendez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Paul A.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Vicki Davis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Cody P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Michael Hernandez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ryan W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Jason Jacobs",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brian L.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Carla Fox",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David J.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Marissa Vance",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Katherine P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Debbie Dean",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kara P.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Judy Ramsey",
        "service_type": "Garage Door Services",
        "tech_name": "Benjamin B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Kelly Duncan",
        "service_type": "Garage Door Repair",
        "tech_name": "Larry H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Maurice Nixon",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nicholas G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Jonathon Campbell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sara J.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Tara Brown",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Amber R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Robin Stanton",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert V.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Marie Owen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Morgan R.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Patricia Hawkins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brandy F.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Courtney Jones",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Linda B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
